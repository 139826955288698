<template>
    <div>
        <div class="video-container">
            <iframe class="video"
                width="100%"
                height="700"
                :src="getUrl()"
                frameborder="0"
                allowfullscreen
                allow="vr"></iframe>

        </div>
    </div>
</template>

<script>
    import V from 'voUtils/V.js'

    export default {
    	voVueComponent: 'video-iframe',
    	data() {
    		return {
    			accept: true
    		}
    	},
    	props: {
    		video: String,
    		type: String
    	},
    	methods: {

    		getUrl: function() {
    			var url = '';
    			if (this.type === 'YouTube' && this.accept) {
    				url = 'https://www.youtube-nocookie.com/embed/';
    				url += this.video;
    				url += '?rel=0';
    			} else if (this.type === 'Vimeo' && this.accept) {
    				url = 'https://player.vimeo.com/video/';
    				url += this.video;
    			} else if (this.type === 'Matterport' && this.accept) {
    				url = 'https://my.matterport.com/show/?m=';
    				url += this.video;
    			}
    			return url;
    		}
    	}
    };
</script>