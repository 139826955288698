import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {
	data: function () {
		return {
			units: [],
			mapSelectedUnit: {},
			mapUnits: [],
			highlightUnit: undefined,
			forceMap: false,
			mapItems: undefined,
			mapInitialZoom: 18,
			mapInitialPos: [37, 18],
			openstreetmap: false
		};
	},
	// props: {
	// 	filter: Object,
	// 	sorting: String,
	// 	max: String
	// },
	beforeMount: function () {
		this.checkOsmConsent();

		var req = {
			"fields": {
				"u.path": {
					"field": "path",
					"filter": "vlang"
				},
				"u.in units": {
					"field": "in units"
				},

				"u.facilityName": {
					"field": "facilityName",
					"filter": "vautolang"
				},
				"u.name": {
					"field": "name",
					"filter": "vautolang"
				},
				"u.type": {
					"field": "type",
					"filter": "vsoption",
					"filterparams": [
						"hpunit.type"
					]
				},
				"u.address.street": {
					"field": "address.street"
				},
				"u.address.city": {
					"field": "address.city"
				},
				"u.petsRestrictions": {
					"field": "petsRestrictions"
				},
				"u.loc.coordinates": {
					"field": "loc.coordinates"
				}
			},
			"filter": {
				"adults": 2,
				"children": 0,
				"babys": 0
			},
			"sorting": "random",
			"view": "map",
			"alternatives": true,
			"max": 250,
			"fuzzy": true,
			"splitAlternatives": false,
			"mapsearch": true,
			"abtest": 2,

		};

		doReq('searchUnits', req)
			.then((res) => {
				//console.log('Units::', res.units);
				this.mapUnits = res.units;
				var i = 0;
				var mapItems = [];
				this.mapUnits.forEach(function (item) {
					if (item.length === 3) {
						i++;
						mapItems[i] = new Array(item[0], item[1], item[2]);
					} else if (item.length > 3) {
						for (let a = 2; a < item.length; a++) {
							i++;
							mapItems[i] = new Array(item[0], item[1], item[a]);
						}
					}

				});

				//console.log('MapUnits::', this.mapUnits);

				this.mapItems = mapItems;
				//	this.mapSelectedUnit = u;
			});

	},

	created() {
		window.addEventListener("resize", this.myEventHandler);
	},
	destroyed() {
		window.removeEventListener("resize", this.myEventHandler);
	},

	mounted: function () {
		window.addEventListener('ccm19WidgetClosed', (event) => {
			this.checkOsmConsent();
		});
	},

	methods: {
		checkOsmConsent: function () {
			const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
			var cookieName = 'allowOpenstreetmap_' + suffix;
			const allowOpenstreet = this.getCookie(cookieName);
			if (allowOpenstreet) {
				this.openstreetmap = true;
			} else {
				const data = CCM.acceptedEmbeddings;
				const targetName = "OpenStreetMap";
				const entry = data.find(item => item.name === targetName);

				if (entry) {
					this.openstreetmap = true;
				} else {
					this.openstreetmap = false;
				}
			}
		},

		getCookie: function (name) {
			const nameEQ = name + "=";
			const ca = document.cookie.split(';');
			for (let i = 0; i < ca.length; i++) {
				let c = ca[i];
				while (c.charAt(0) === ' ') c = c.substring(1, c.length);
				if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
			}
			return null;
		},

		setCookie: function (name, value, days) {
			const date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			const expires = "expires=" + date.toUTCString();
			document.cookie = name + "=" + value + ";" + expires + ";path=/";
		},

		allowOpenstreetmap: function () {
			const suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
			var cookieName = 'allowOpenstreetmap_' + suffix;
			this.setCookie(cookieName, true, VOFFICE.settings.osmCookieDays);
			this.openstreetmap = true;
		},

		showOpenstreetmap: function () {
			this.openstreetmap = true;
		},

		getUnitSearchPath: function (path, o) {
			return path;
		},

		getUnitSearchTarget: function (id) {
			return 'unit_' + id;

		},

		myEventHandler(e) {
			//console.log(window.innerWidth);
		}
	},
	watch: {
		mapSelectedUnit: function (nv) {
			//	V.log("mapSelectedUnit", nv);
			if (nv && nv.lazy) {

				var req = {
					"fields": {
						"u.path": {
							"field": "path",
							"filter": "vlang"
						},
						"u.in units": {
							"field": "in units"
						},

						"u.facilityName": {
							"field": "facilityName",
							"filter": "vautolang"
						},
						"u.name": {
							"field": "name",
							"filter": "vautolang"
						},
						"u.type": {
							"field": "type",
							"filter": "vsoption",
							"filterparams": [
								"hpunit.type"
							]
						},
						"u.beds": {
							"field": "beds",
						},
						"u.rooms": {
							"field": "rooms",
						},
						"u.bedrooms": {
							"field": "bedrooms",
						},
						"u.bathrooms": {
							"field": "bathrooms",
						},
						"u.living_room": {
							"field": "living_room",
						},
						"u.pets": {
							"field": "pets",
						},
						"u.idxImage": {
							"field": "idxImage",
						},
						"u.squareMeter": {
							"field": "squareMeter",
						},
						"u.address.street": {
							"field": "address.street"
						},
						"u.address.city": {
							"field": "address.city"
						},
						"u.petsRestrictions": {
							"field": "petsRestrictions"
						},
						"u.loc.coordinates": {
							"field": "loc.coordinates"
						}
					},
					"filter": {
						"adults": 0,
						"children": 0,
						"babys": 0
					},
					ids: [nv.info[2]]
				};

				V.log("mapSelectedUnitRequest::", req);


				doReq('searchUnits', req)
					.then((res) => {
						V.log("mapSelectedUnit:::", res.units);
						if (res.units && res.units.length) {
							Object.assign(nv, res.units[0]);
							nv.lazy = false;
						}

					});

			}
		}
	}

};